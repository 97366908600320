//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  Events,
  DialogEventBus,
} from '@/components/Modals/XModals/DialogEventBus'
import { dataURItoBlob } from '@/utils/DataUtils'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'

export default {
  name: 'AvatarSelector',
  props: {
    default: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '/assets/i/placeholders/photo.svg',
    },
    size: {
      type: Number,
      default: 120,
    },
    maxWidth: {
      type: Number,
      default: 270,
    },
    maxDescriptionWidth: {
      type: Number,
      default: 110,
    },
    entityType: String,
    vertical: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    'close-icon': createSVGComponent({
      icon: 'cross_close',
      size: 16,
      sizeStorage: 14,
    }),
  },
  data () {
    return {
      avatar: this.default,
      croppedAvatar: null,
      types: ['image/jpeg', 'image/png', 'image/gif'],
    }
  },
  computed: {
    isPresent () {
      return this.avatar && this.avatar !== this.placeholder
    },
  },
  mounted () {
    this.cropperHandler = ({ data }) => {
      this.croppedAvatar = data
      this.$emit('upload', {
        file: dataURItoBlob(this.croppedAvatar),
        src: this.croppedAvatar,
      })
    }
    DialogEventBus.$on(Events.SEND_DATA_FROM_DIALOG, this.cropperHandler)
  },
  beforeDestroy () {
    DialogEventBus.$off(Events.SEND_DATA_FROM_DIALOG, this.cropperHandler)
  },
  methods: {
    dragEnter () {
      const dropZone = this.$refs.outlineMask
      dropZone.classList.add('active')
    },
    dragLeave () {
      const dropZone = this.$refs.outlineMask
      dropZone.classList.remove('active')
    },
    selectImage (event) {
      const input = event.target
      const file = input.files && input.files[0]

      if (!file) return

      const { name } = file
      const { fileInputLabel } = this.$refs
      fileInputLabel && fileInputLabel.setAttribute('title', name)

      input.value = ''

      this.upload(file)
    },
    drop (event) {
      event.preventDefault()

      const dropZone = this.$refs.outlineMask
      dropZone.classList.remove('active')

      const { files } = event.dataTransfer
      if (files.length === 0) return

      const file = files[0]
      this.upload(file)
    },
    removeAvatar () {
      this.croppedAvatar = this.avatar = this.placeholder
      this.$forceUpdate()

      const { fileInputLabel, fileInput } = this.$refs
      fileInputLabel && fileInputLabel.removeAttribute('title')
      fileInput.value = ''

      this.$emit('upload', { file: null })
    },
    upload (file) {
      if (!file || file.type === '' || this.types.indexOf(file.type) === -1) { return }

      const reader = new window.FileReader()
      reader.onload = e => {
        this.avatar = e.target.result

        DialogEventBus.$emit(Events.OPEN_DIALOG, {
          instance: 'avatar-cropper',
          data: this.avatar,
        })

        this.$emit('open-crop', {
          instance: 'avatar-cropper',
          data: this.avatar,
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
