

































































































import { Component, Vue, Ref } from 'vue-property-decorator'
import { format } from 'quasar'

import AvatarSelector from '@/components/Modals/Default/_CommonUI/AvatarSelector.vue'
import BaseInput from '@/components/Modals/Default/TwoFactorAuth/components/BaseInput.vue'

import {
  loginStore,
  teamsStore,
  uiStore,
} from '@/store'

import {
  Events,
  DialogEventBus,
} from '@/components/Modals/XModals/DialogEventBus'
import { routerLogger } from '@/loggers'

const { capitalize } = format

@Component({
  name: 'TheUserProfileView',
  components: {
    BaseInput,
    AvatarSelector,
    ContentHeader: () => import('./components/ContentHeader.vue'),
  },
})
export default class TheUserProfileView extends Vue {
  @Ref() readonly firstNameInput!: BaseInput

  icon: Blob | null = null
  isIconChanged = false

  firstName = ''
  lastName = ''
  patronymic = ''

  loading = false
  err = ''

  /**
   * Default inputs length limit
   */
  limit = 100

  /**
   * Set limit for inputs
   * Start listening event bus to change avatar and dialog of it
   */
  mounted () {
    this.limit = window.FEATURES.max_username_part_length
    DialogEventBus.$on(
      Events.OPEN_DIALOG,
      (payload: { instance: string; data: unknown }) => {
        const { instance, data } = payload
        uiStore.actions.showModal({
          instance,
          payload: { data },
        })
      },
    )
  }

  /**
   * Destroy event bus
   */
  beforeDestroy () {
    DialogEventBus.$off()
  }

  get btnNextLabel (): string {
    return capitalize(this.$t('common.next').toString())
  }

  /**
   * Navigate to CreateTeam screen
   */
  onClickBackArrow () {
    this.$router.push({ name: 'AddToTeam' })
  }

  /**
   * Validate names inputs and if it isn't - navigate to Main with bad profile
   * Else change names by api and navigate to Main page after
   */
  async onSubmit () {
    if (
      !this.firstName.replace(/\s/g, '') &&
      !this.lastName.replace(/\s/g, '')
    ) {
      loginStore.actions.setBadProfileStatus(true)
      this.$router.push({ name: 'Main' }).catch(e => {
        routerLogger.debug('Router warning Main: ', e)
      })
      window.goal('onboardingAction', { onboarding: 'Расскажите о себе — «Пропустить шаг»' })
      return
    }

    window.goal('onboardingAction', { onboarding: 'Расскажите о себе — «Продолжить»' })

    if (this.firstName.trim()) {
      window.goal('onboardingAction', { onboarding: 'Расскажите о себе — заполнили имя' })
    }
    if (this.lastName.trim()) {
      window.goal('onboardingAction', { onboarding: 'Расскажите о себе — заполнили фамилию' })
    }

    this.loading = true

    try {
      loginStore.actions.setProfileInfo({
        firstName: this.firstName.trim(),
        secondName: this.lastName.trim(),
        patronymic: this.patronymic.trim(),
        avatar: this.icon,
      })
      loginStore.actions.setBadProfileStatus(false)
      this.$router.push({ name: 'Main' }).catch(e => {
        routerLogger.debug('Router warning Main: ', e)
      })
      // todo initialHook()
    } catch (e) {
      this.err =
        (e.details.given_name ||
          e.details.family_name ||
          e.details.file ||
          e.details?.['']) ??
        e.error
      this.firstNameInput.focus()
    }
    this.loading = false
  }

  /**
   * Changing avatar data and flag of changing it
   */
  onAvatarUpload ({ file }: { file: Blob }) {
    this.icon = file
    this.isIconChanged = true
    window.goal('onboardingAction', {
      onboarding: 'Расскажите о себе — автарка установлена',
    })
  }

  /**
   * Send metric for changing avatar icon (only choosing, saving - in store)
   */
  onAvatarSelectorClick () {
    window.goal('onboardingAction', {
      onboarding: 'Расскажите о себе — клик по пикеру аватарки',
    })
  }

  /**
   * Get use patronymic un the current team
   */
  get usePatronymic (): boolean | undefined {
    return teamsStore.getters.currentTeam.usePatronymic
  }
}
