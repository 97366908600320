var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"avatar-selector",class:[
    _vm.vertical
      ? 'o-flex-column o-flex-column--center'
      : 'o-flex-row o-flex-row--center' ],style:({ maxWidth: (_vm.maxWidth + "px") }),attrs:{"data-cy":"select-avatar"}},[_c('div',{staticClass:"avatar-selector__frame o-flex-item-solid"},[_c('div',{staticClass:"avatar-image-wrapper",style:({
        width: (_vm.size + "px"),
        height: (_vm.size + "px"),
        backgroundImage: ("url('" + (_vm.croppedAvatar || _vm.avatar || _vm.placeholder) + "')"),
      }),attrs:{"data-cy":"change-avatar"}}),(!_vm.disabled && _vm.isPresent)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ text: _vm.$t('modals.AvatarSelector.delete') }),expression:"{ text: $t('modals.AvatarSelector.delete') }"}],staticClass:"close-icon-wrapper",on:{"click":function($event){$event.stopPropagation();return _vm.removeAvatar.apply(null, arguments)}}},[_c('close-icon')],1):_vm._e()]),(_vm.showLabel)?_c('div',{staticClass:"avatar-selector__description o-flex-column",style:(Object.assign(
        {
          maxWidth: _vm.maxDescriptionWidth ? (_vm.maxDescriptionWidth + "px") : 'auto',
        },
        _vm.vertical
          ? { 'margin-top': '10px', 'text-align': 'center' }
          : { 'margin-left': '15px' }
      ))},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.$t('modals.AvatarSelector.uploadText.part1'))),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t('modals.AvatarSelector.uploadText.part2'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('modals.AvatarSelector.uploadText.part3'))+" ")]})],2):_vm._e(),_c('div',{ref:"outlineMask",staticClass:"avatar-selector__mask o-absolute-stretch"}),(!_vm.disabled)?_c('label',{ref:"fileInputLabel",staticClass:"avatar-selector__label o-absolute-stretch",attrs:{"data-cy":"uploaded-avatar"},on:{"dragenter":_vm.dragEnter,"dragleave":_vm.dragLeave,"dragover":function($event){$event.preventDefault();return (function () {}).apply(null, arguments)},"drop":_vm.drop}},[_c('input',{ref:"fileInput",attrs:{"accept":_vm.types.join(','),"type":"file"},on:{"change":_vm.selectImage}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }